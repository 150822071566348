<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('reportAssignment') }}
      </v-card-title>
      <DateFilters @onSendDate='serachReport' />
      <v-row class='px-2'>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='4'
        >
          <v-text-field
            v-model.trim='searchtext'
            dense
            outlined
            :label="$t('search')"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :loading='loading'
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
      </v-data-table>
    </v-card>
  </div>
</template>
<script>

import useAssignment from './useAssignment'
import DateFilters from '@/components/basicComponents/DateFilters.vue'


export default {
  components: {
    DateFilters,
  },
  setup() {



    return {
      ...useAssignment(),
    }
  },
}
</script>
