import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import { ref } from '@vue/composition-api'

export default function useAssignment() {
  const searchtext = ref('')
  const online_status_id = ref('')
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'id',
      width: 50,
    },
  ])
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const loading = ref(false)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const exportLoading = ref(false)
  const serachReport = (start, end) => {
    console.log('start : ', start, ' to : ', end)
  }

  return {
    searchtext,
    online_status_id,
    options,
    footer,
    columns,
    dataTableList,
    totalDataTableList,
    loading,
    segmentId,
    totalPage,
    exportLoading,
    serachReport,
  }
}
